<template>
    <div></div>
</template>

<script>
    export default {
        name: "topic_payment",
        components: {},
        data: () => {
            return {}
        },
        created() {},
        methods: {}
    }
</script>
<style lang="scss" scoped></style>
